import {
  Box,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Slide,
  Stack,
  IconButton
} from '@mui/material';
import { useContext, useRef, useState, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { icons } from 'src/assets';
import { Web3AuthContext } from 'src/contexts/Web3Auth';
import useResponsive from 'src/hooks/useResponsive';
import { TransitionProps } from '@mui/material/transitions';
import Logo from 'src/components/Logo';

type TMenuItem = {
  title: string;
  to: string;
  href?: string;
  icon: string;
  showNotification?: boolean;
};

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['background', 'fill'])};
            color:${theme.header.menuItemColor};
            border-radius: 0.25rem;
      
            & .MuiListItemIcon-root {
              min-width: unset;
              margin-right: ${theme.spacing(1)};
            };

            & .MuiTypography-root {
             font-size:0.625rem;
              
            }

            &:hover {
              background: ${theme.colors.primary.lighter};
              // color:${theme.header.menuItemColorActive};
              font-weight: 700;
              & .MuiListItemIcon-root {
                 filter: brightness(100) invert(1); //--> make icon black
              }

              & .MuiTypography-root {
                font-weight: 700;
                
              }
             }

            
            &.active,
            &:active
             {
              background: ${theme.header.menuItemBgActive};
              color:${theme.header.menuItemColorActive};
              font-weight: 700;
              & .MuiListItemIcon-root {
                filter: brightness(0) invert(1); //--> make icon white
              }

              & .MuiTypography-root {
                font-weight: 700;
                
              }
             }
             

            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: ${theme.colors.primary.main};
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const ISSUER_MENU = [
  {
    title: 'Digital Credentials',
    to: '/dashboard/badges',
    icon: icons.badgeIssuerSec
  },
  {
    title: 'Earned Credentials',
    to: '/dashboard/earned-badges',
    icon: icons.badgeUserSec,
    showNotification: true
  },
  // {
  //   title: 'Analytics',
  //   to: '/dashboard/analytics',
  //   icon: icons.analytics
  // },
  {
    title: 'Profile',
    to: '/dashboard/profile',
    icon: icons.profileSec
  },
  {
    title: 'Settings',
    to: '/dashboard/settings',
    icon: icons.settingSec
  }
];
const USER_MENU = [
  {
    title: 'Overview',
    to: '/dashboard/overview',
    icon: icons.overviewSec
  },
  {
    title: 'Earned Credentials',
    to: '/dashboard/earned-badges',
    icon: icons.badgeUserSec,
    showNotification: true
  },
  {
    title: 'Profile',
    to: '/dashboard/profile',
    icon: icons.profileSec
  },
  {
    title: 'Settings',
    to: '/dashboard/settings',
    icon: icons.settingSec
  }
];

function HeaderMenu({ isMenuOpen, setIsMenuOpen }) {
  const theme = useTheme();
  // console.log('HeaderMenu  theme:', theme);
  const {
    logoutFromCustomAuth,
    user: {
      isIssuer,
      userDetails: { hasAwaitingIssue }
    }
  } = useContext(Web3AuthContext);
  const ref = useRef<any>(null);
  const xlUp = useResponsive('up', 'xl');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const MENU = isIssuer ? ISSUER_MENU : USER_MENU;

  return (
    <>
      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={toggleMenu}
        TransitionComponent={Transition}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '100%',
            background: theme.colors.primary.main,
            p: 0
          }
        }}
      >
        <Stack width="100%">
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`0.5px solid ${theme.colors.primary.lighter}`}
            px="1rem"
            py="0.5rem"
          >
            <Logo
              white
              sx={{
                gap: '0.2rem'
              }}
              logoSx={{ width: '1.75rem', height: '1.75rem' }}
              brandSx={{ width: '7.5rem', height: '1.5rem' }}
            />
            <IconButton color="primary" onClick={toggleMenu}>
              <Box
                width={'3rem'}
                height={'3rem'}
                component={'img'}
                src={icons.closeWhite}
              />
            </IconButton>
          </Stack>
          <List
            component={Box}
            flexDirection="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="1rem"
            sx={{
              marginTop: '1.5rem',
              paddingInline: '2rem'
            }}
          >
            {MENU.map((menuItem) => {
              return (
                <ListItem
                  key={menuItem.title}
                  component={NavLink}
                  to={menuItem.to}
                  sx={{
                    color: 'common.white',
                    borderBottom: `0.5px solid ${theme.colors.primary.lighter}`,
                    padding: '1rem',
                    textAlign: 'center'
                  }}
                  onClick={toggleMenu}
                >
                  <ListItemText
                    primaryTypographyProps={{ noWrap: true }}
                    primary={menuItem.title}
                    sx={{
                      textTransform: 'uppercase'
                    }}
                  />
                </ListItem>
              );
            })}
            <ListItem
              sx={{
                color: 'common.white',
                borderBottom: `0.5px solid ${theme.colors.primary.lighter}`,
                padding: '1rem',
                textAlign: 'center'
              }}
              onClick={logoutFromCustomAuth}
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Log out"
                sx={{
                  textTransform: 'uppercase'
                }}
              />
            </ListItem>
          </List>
        </Stack>
      </Dialog>
      <ListWrapper display={{ xs: 'none', sm: 'flex' }}>
        <List disablePadding component={Box} display="flex" gap="1rem">
          {MENU.map((menuItem) => {
            return (
              <ListItem
                sx={{
                  height: '2.25rem'
                }}
                key={menuItem.title}
                component={NavLink}
                to={menuItem.to}
              >
                <ListItemIcon>
                  <Box
                    width={xlUp ? 20 : '1rem'}
                    height={xlUp ? 20 : '1rem'}
                    component={'img'}
                    src={menuItem.icon}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary={menuItem.title}
                />
              </ListItem>
            );
          })}
        </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;
