import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'nprogress/nprogress.css';
import { IntercomProvider } from 'react-use-intercom';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import ENV from './config/environment';
import { Web3AuthProvider } from './contexts/Web3Auth';
import './locales/configuration/i18n';

const INTERCOM_APP_ID = ENV.INTERCOM_APP_ID;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 200000 // 2 minutes
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <Web3AuthProvider>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <GoogleOAuthProvider clientId={ENV.GOOGLE_CLIENT_ID}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </GoogleOAuthProvider>
        </IntercomProvider>

        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </Web3AuthProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
