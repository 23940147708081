import { ElementType, Suspense, lazy, useContext } from 'react';
import { useRoutes } from 'react-router';
import { Navigate, useSearchParams } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import HeaderOnlyLayout from 'src/layouts/HeaderOnlyLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { Web3AuthContext } from './contexts/Web3Auth';
import useResponsive from './hooks/useResponsive';
const Loader = (Component: ElementType) => (props: object) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const PageLoading = Loader(
  lazy(() => import('src/components/PageLoading/PageLoading'))
);

// public

const Login = Loader(lazy(() => import('src/pages/public/login/Login')));
const QrLogin = Loader(lazy(() => import('src/pages/public/login/QrLogin')));

const Register = Loader(
  lazy(() => import('src/pages/public/register/Register'))
);
const ForgetPassword = Loader(
  lazy(() => import('src/pages/public/forget-password/ForgetPassword'))
);

const BadgeDetailPublic = Loader(
  lazy(() => import('src/pages/public/badge/BadgeDetailPublic'))
);
const IssuerPublicProfile = Loader(
  lazy(() => import('src/pages/public/profile/IssuerPublicProfile'))
);
const UserPublicProfile = Loader(
  lazy(() => import('src/pages/public/profile/UserPublicProfile'))
);

// dashboard
const Overview = Loader(lazy(() => import('src/pages/dashboard/overview')));
const EarnedBadges = Loader(
  lazy(() => import('src/pages/dashboard/badge/user/EarnedBadges'))
);
const Badges = Loader(
  lazy(() => import('src/pages/dashboard/badge/issuer/Badges'))
);
const BadgeDetailAsIssuer = Loader(
  lazy(() => import('src/pages/dashboard/badge/issuer/BadgeDetailAsIssuer'))
);
const BadgeDetailAsUser = Loader(
  lazy(() => import('src/pages/dashboard/badge/user/BadgeDetailAsUser'))
);
const ImportedBadgeDetailAsUser = Loader(
  lazy(() => import('src/pages/dashboard/badge/user/ImportedBadgeDetailAsUser'))
);

const ImportedBadgeDetailPublic = Loader(
  lazy(() => import('src/pages/public/badge/ImportedBadgeDetailPublic'))
);
const ImportBadge = Loader(
  lazy(() => import('src/pages/dashboard/badge/user/ImportBadge'))
);
const CreateBadge = Loader(
  lazy(() => import('src/pages/dashboard/badge/issuer/CreateBadge'))
);
const EditBadge = Loader(
  lazy(() => import('src/pages/dashboard/badge/issuer/EditBadge'))
);
const IssueBadge = Loader(
  lazy(() => import('src/pages/dashboard/badge/issuer/IssueBadge'))
);
const ProfileAsIssuer = Loader(
  lazy(() => import('src/pages/dashboard/account/ProfileAsIssuer'))
);
const ProfileAsUser = Loader(
  lazy(() => import('src/pages/dashboard/account/ProfileAsUser'))
);
const Settings = Loader(
  lazy(() => import('src/pages/dashboard/account/settings'))
);
const IssuerPlan = Loader(
  lazy(() => import('src/pages/dashboard/account/issuerPlan'))
);

export default function Router() {
  const { user, isLoading, isInitialized, signer } =
    useContext(Web3AuthContext);
  const isLoggedIn = !!user?.email;
  const isIssuer = isLoggedIn && user.isIssuer;

  const [searchParams] = useSearchParams();
  const hasCallback = searchParams.has('callback');
  const callbackUrl = searchParams.get('callbackUrl') || '';
  // console.log('hasCallback', {
  //   isLoggedIn,
  //   hasCallback,
  //   callbackUrl,
  //   pathname: window.location.pathname
  // });

  const smDown = useResponsive('down', 'sm');

  if (process.env.REACT_APP_ENV !== 'production') {
    console.log('user', {
      user,
      signer,
      isIssuer,
      isInitialized,
      isLoading,
      isLoggedIn
    });
  }

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <Navigate
          to={
            isLoggedIn
              ? isIssuer
                ? '/dashboard/badges'
                : '/dashboard/overview'
              : '/login'
          }
          replace
        />
      )
    },
    {
      path: '/login',
      element: isLoggedIn ? (
        <Navigate
          to={
            callbackUrl
              ? callbackUrl
              : isIssuer
              ? '/dashboard/badges'
              : '/dashboard/overview'
          }
        />
      ) : (
        <Login />
      )
    },
    {
      path: '/qr-login',
      element: isLoggedIn ? (
        <Navigate
          to={
            callbackUrl
              ? callbackUrl
              : isIssuer
              ? '/dashboard/badges'
              : '/dashboard/overview'
          }
        />
      ) : (
        <QrLogin />
      )
    },

    {
      path: '/register',
      element: isLoggedIn ? (
        <Navigate to={isIssuer ? '/dashboard/badges' : '/dashboard/overview'} />
      ) : (
        <Register />
      )
    },
    {
      path: '/forget-password',
      element: isLoggedIn ? (
        <Navigate to={isIssuer ? '/dashboard/badges' : '/dashboard/overview'} />
      ) : (
        <ForgetPassword />
      )
    },
    {
      path: '/',
      element: <HeaderOnlyLayout />,
      children: [
        {
          path: 'user/:userAddress',
          element: <UserPublicProfile />
        },
        {
          path: 'user/badge-detail/:issueId',
          element: <BadgeDetailPublic />
        },
        {
          path: 'user/imported-badge-detail/:userAddress/:importedBadgeId',
          element: <ImportedBadgeDetailPublic />
        },

        {
          path: 'issuer/:issuerAddress',
          element: <IssuerPublicProfile />
        },
        {
          path: 'issuer/:issuerAddress/badge-detail/:badgeId',
          element: <BadgeDetailPublic />
        }
      ]
    },
    {
      path: '/dashboard',
      element: isLoggedIn ? (
        <BaseLayout />
      ) : (
        <Navigate
          to={`/login${
            hasCallback ? `?callbackUrl=${window.location.pathname}` : ''
          }`}
          replace
        />
      ),
      children: [
        {
          path: 'overview',
          element: !isIssuer ? (
            <Overview />
          ) : (
            <Navigate to={'/dashboard/badges'} />
          )
        },
        { path: 'earned-badges', element: <EarnedBadges /> },
        {
          path: 'badges',
          element: isIssuer ? (
            <Badges />
          ) : (
            <Navigate to={'/dashboard/overview'} replace />
          )
        },
        {
          path: 'badges/badge-detail/:badgeId',
          element: isIssuer ? (
            <BadgeDetailAsIssuer />
          ) : (
            <Navigate to={'/dashboard/overview'} replace />
          )
        },
        {
          path: 'earned-badges/import-badge',
          element: smDown ? (
            <ImportBadge />
          ) : (
            <Navigate to={'/dashboard/overview'} replace />
          )
        },
        {
          path: 'earned-badges/badge-detail/:issueId',
          element: <BadgeDetailAsUser />
        },
        {
          path: 'earned-badges/imported-badges/badge-detail/:importedBadgeId',
          element: <ImportedBadgeDetailAsUser />
        },
        {
          path: 'badges/create-badge',
          element: isIssuer ? (
            <CreateBadge />
          ) : (
            <Navigate to={'/dashboard/overview'} replace />
          )
        },
        {
          path: 'badges/issue-badge/:badgeId',
          element: isIssuer ? (
            <IssueBadge />
          ) : (
            <Navigate to={'/dashboard/overview'} replace />
          )
        },
        {
          path: 'badges/edit-badge/:badgeId',
          element: isIssuer ? (
            <EditBadge />
          ) : (
            <Navigate to={'/dashboard/overview'} replace />
          )
        },

        {
          path: 'profile',
          element: isIssuer ? <ProfileAsIssuer /> : <ProfileAsUser />
        },
        { path: 'settings', element: <Settings /> },

        { path: 'issuer-plan', element: <IssuerPlan /> }
      ]
    }
  ]);

  if (isLoading) {
    return <PageLoading />;
  }

  return routes;
}
