import axios from 'axios';
import ENV from '../config/environment';

const baseUrl = ENV.BASE_URL;

const getToken = () => {
  const idToken = localStorage.getItem('diplomadeIdToken');
  const refreshToken = localStorage.getItem('diplomadeRefreshToken');
  const authToken = `${idToken},${refreshToken}`;
  return idToken ? authToken.replaceAll('"', '') : '';
};

export const api = axios.create({
  baseURL: baseUrl
});

api.interceptors.request.use(async (config) => {
  const idToken = getToken();
  // console.log("idToken:", idToken)
  config.headers['Authorization'] =
    config.headers['Authorization'] || idToken || '';

  // console.log("request config:", config)
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log('error', error);

    // if (
    //   error.response.status === 400 &&
    //   ![
    //     'Email is not registered',
    //     'Email already registered',
    //     'Wrong password',
    //     'Email not verified',
    //     'Password must contain at least 1 lowercase, 1 uppercase, and 1 number',
    //     'Password should be min 8, max 20 characters',
    //     'please ensure you entered the correct name and provided high quality id document image',
    //     'failed id verification, faces do not match'
    //   ].includes(error.response.data.message)
    // ) {
    //   throw new Error('Something went wrong!');
    // }
    throw error?.response?.data || error;
  }
);
