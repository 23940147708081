import {
  Badge as MuiBadge,
  Box,
  Link,
  Popover,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
  IconButton
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { icons } from 'src/assets';
import { CenteredStack } from 'src/components/Stack/CenteredStack';
import { Web3AuthContext } from 'src/contexts/Web3Auth';
import { Badge, IssueStatus } from 'src/models/badge';
import { QueryKeys } from 'src/models/queryKeys';
import { getBadgesAsUser } from 'src/services/badge';
import { calculateTimeDifference } from 'src/utils/times';
type Props = {};

const NotificationBox = styled(Box)(({ theme }) => ({}));
const StyledPopover = styled(Popover)(({ theme }) => ({
  border: '0.5px solid rgba(106, 118, 159, 0.6)',
  borderRadius: '0.5rem',
  minWidth: '18.75rem'
}));

export default function HeaderNotifications({}: Props) {
  const ref = useRef<any>(null);
  const theme = useTheme();
  const {
    user: {
      isIssuer,
      userDetails: { hasAwaitingIssue }
    }
  } = useContext(Web3AuthContext);

  const [isOpen, setOpen] = useState<boolean>(false);

  const { isLoading, data: earnedBadgesResponse } = useQuery({
    queryKey: [QueryKeys.EarnedBadges],
    queryFn: () => getBadgesAsUser(1, 200),
    enabled: hasAwaitingIssue
  });

  const awaitingBadges: Badge[] =
    earnedBadgesResponse?.paginatedItems?.filter(
      (b: Badge) =>
        b.status === IssueStatus.AWAIT_SIGNATURES && !b.recipientSignature
    ) || [];

  const handleClose = (): void => {
    setOpen(false);
  };

  const onOpenNotifications = () => {
    if (awaitingBadges.length > 0) {
      setOpen(true);
    }
  };
  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton
          disableRipple
          sx={{
            cursor: hasAwaitingIssue ? 'pointer' : 'auto',
            '&:hover': {
              background: 'none'
            }
          }}
          onClick={onOpenNotifications}
          ref={ref}
        >
          <MuiBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent={
              hasAwaitingIssue ? (
                <Box
                  width="0.5rem"
                  height="0.5rem"
                  borderRadius="50%"
                  bgcolor="error.main"
                />
              ) : (
                false
              )
            }
          >
            <Box
              width="2.4rem"
              height="2.4rem"
              component="img"
              src={icons.notificationSec}
            />
          </MuiBadge>
        </IconButton>
      </Tooltip>

      <StyledPopover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Stack>
          <Stack p="0.75rem 1rem;">
            <Typography fontSize="0.875rem" fontWeight={600}>
              {' '}
              Notifications
            </Typography>
          </Stack>

          {awaitingBadges?.map((badge) => {
            return (
              <Link
                underline="none"
                component={RouterLink}
                key={badge.id}
                to={{
                  pathname: `earned-badges/badge-detail/${badge.issueId}`
                }}
                sx={{
                  pointerEvents:
                    badge.status === IssueStatus.PENDING ? 'none' : 'auto',
                  '&:hover': {
                    background: theme.palette.background.default
                  }
                }}
                onClick={handleClose}
              >
                <CenteredStack
                  direction="row"
                  gap="0.5rem"
                  p="0.5rem 1rem;"
                  borderTop={`2px solid ${theme.palette.background.default}`}
                >
                  <Box
                    component="img"
                    src={badge.imageUrl}
                    width="2.25rem"
                    height="2.25rem"
                  />
                  <Stack>
                    <Typography fontSize="0.625rem">
                      New credentials "<strong>{badge.title}</strong>" was
                      issued to you
                    </Typography>
                    <Typography variant="subtitle2">
                      {calculateTimeDifference(badge.issueDate)}
                    </Typography>
                  </Stack>
                </CenteredStack>
              </Link>
            );
          })}
        </Stack>
      </StyledPopover>
    </>
  );
}
