import { FC, ReactNode, useContext, useEffect } from 'react';

import { Box, styled, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { getUnixTime } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useIntercom } from 'react-use-intercom';
import { Web3AuthContext } from '../../contexts/Web3Auth';
import Header from './Header';
interface BaseLayoutProps {
  children?: ReactNode;
}

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto'
}));
const MainStyle = styled('div')(({ theme }) => ({
  height: '100%',
  zIndex: 5,
  paddingTop: `calc(${theme.header.height} + 2rem)`,
  paddingBottom: '2rem',
  paddingInline: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    paddingInline: '6rem'
  }
}));

const BaseLayout: FC<BaseLayoutProps> = () => {
  const theme = useTheme();
  const {
    user,
    user: {
      email,
      walletAddress,
      isIssuer,
      emailHMACForIntercom,
      userDetails: {
        username,
        profileImageUrl,
        bio,
        jobTitle,
        linkedinUrl,
        twitterUrl,
        githubUrl,
        registrationDate
      }
    }
  } = useContext(Web3AuthContext);
  // console.log('BaseLayout user:', user);

  const isProfileNeedComplete = !username || !bio || !profileImageUrl;
  const toNavigate = isIssuer ? 'issuer' : 'user';
  const publicProfileUrl = `${window.location.origin}/${toNavigate}/${walletAddress}`;

  const { boot, update } = useIntercom();
  boot({
    name: username || walletAddress,
    email,
    createdAt: getUnixTime(new Date(registrationDate)),
    // userId: walletAddress,
    userHash: emailHMACForIntercom,
    avatar: {
      type: 'avatar',
      imageUrl: profileImageUrl
    },
    customAttributes: {
      isIssuer,
      walletAddress,
      isUserCompleteProfile: !isProfileNeedComplete,
      username,
      profileImageUrl,
      bio,
      jobTitle,
      linkedinUrl,
      twitterUrl,
      githubUrl,
      publicProfileUrl
    }
  });

  useEffect(() => {
    // console.log('useEffect isProfileNeedComplete:', isProfileNeedComplete);
    update({
      customAttributes: {
        isIssuer,
        walletAddress,
        isUserCompleteProfile: !isProfileNeedComplete,
        username,
        profileImageUrl,
        bio,
        jobTitle,
        linkedinUrl,
        twitterUrl,
        githubUrl,
        publicProfileUrl
      }
    });
  }, [user]);

  return (
    <>
      <Helmet>
        {isIssuer && <meta name="viewport" content="width=1200" />}
      </Helmet>
      <RootStyle>
        <Header />

        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
};

export default BaseLayout;
