import { useContext, useRef, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useTheme
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useIntercom } from 'react-use-intercom';
import { themeColors } from 'src/theme/schemes/DiplomadeTheme';
import { icons } from '../../../../assets/index';
import { Web3AuthContext } from '../../../../contexts/Web3Auth';
import useResponsive from '../../../../hooks/useResponsive';
import { formatAddress } from '../../../../utils/helperUtils';

const StyledPopover = styled(Popover)(({ theme }) => ({
  border: '0.5px solid rgba(106, 118, 159, 0.6)',
  borderRadius: 6,
  minWidth: 156
}));

const UserActionBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  background: theme.palette.common.white,
  border: '0.5px solid rgba(106, 118, 159, 0.6)',
  borderRadius: '1.05rem',
  width: '3.6rem',
  height: '2.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}));

const MenuWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  // paddingTop: theme.spacing(2),
  // gap: theme.spacing(1.6),
  background: '#F7F8FF'
}));
const MenuUserBoxWrapper = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(0, 1.6)
}));
const MenuUserBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  // gap: theme.spacing(1)
}));

const MenuNavBoxWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center'
}));
const MenuNavBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTypography-root': {
    fontSize: '0.75rem',
    fontWeight: 300,
    display: 'flex',
    gap: '0.25rem'
  },
  '& .MuiList-root': {
    padding: '0 '
  },
  '& .MuiListItem-root': {
    '& a': {
      alignItems: 'center'
    },
    '&:hover': {
      background: '#F7F8FF'
    }
  }
}));

const planColors = new Map<string, string[]>([
  ['Trial', [themeColors.secondary]], //secondary
  ['Basic', [themeColors.warning]], // warning
  ['Business', [themeColors.tertiary]], //success
  ['Enterprise', [themeColors.primary]] //primary
]);

function HeaderUserBox() {
  const {
    user: { email, walletAddress, isIssuer, userDetails },
    logoutFromCustomAuth
  } = useContext(Web3AuthContext);

  const planTitle = userDetails?.currentPackage?.header;
  const profileImageUrl = userDetails?.profileImageUrl;

  const { shutdown: shutdownIntercom } = useIntercom();
  const toNavigate = isIssuer ? 'issuer' : 'user';

  const xlDown = useResponsive('down', 'xl');
  const theme = useTheme();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const onLogOutClick = () => {
    shutdownIntercom();
    logoutFromCustomAuth();
  };

  const onClickCopyWalletAddress = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(walletAddress);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const onClickCopyEmail = () => {
    navigator.clipboard.writeText(email);
  };

  return (
    <>
      <UserActionBox ref={ref} onClick={handleOpen}>
        <Box
          width="0.5rem"
          height="0.25rem"
          component="img"
          src={icons.arrowDown}
        />

        {profileImageUrl ? (
          <Box
            component="img"
            src={profileImageUrl}
            sx={{
              border: `0.311111px solid ${theme.colors.secondary.main}`,
              boxShadow: '0px 18.6667px 62.2222px rgba(0, 0, 0, 0.1)',
              width: '1.875rem',
              height: '1.875rem',
              borderRadius: '50%',
              mr: '-4px'
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#F7F8FF',
              border: `0.311111px solid ${theme.colors.secondary.main}`,
              boxShadow: '0px 18.6667px 62.2222px rgba(0, 0, 0, 0.1)',
              // width: xlDown ? 27 : 40,
              // height: xlDown ? 27 : 40,
              width: '1.875rem',
              height: '1.875rem',
              borderRadius: '50%',
              mr: '-6px'
            }}
          >
            <Box
              // width={xlDown ? 7 : 11}
              // height={xlDown ? 11 : 16}
              width="1rem"
              height="0.75rem"
              component="img"
              src={icons.profilePr}
            />
          </Box>
        )}
      </UserActionBox>

      <StyledPopover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ marginTop: '0.5rem' }}
      >
        <MenuWrapper>
          <MenuUserBoxWrapper>
            <MenuUserBox>
              <Box flexDirection="column" display="flex" gap={1}>
                <Box
                  alignItems="center"
                  flexDirection="row"
                  display="flex"
                  gap={1}
                >
                  {profileImageUrl ? (
                    <Box
                      component="img"
                      src={profileImageUrl}
                      sx={{
                        border: `0.311111px solid ${theme.colors.secondary.main}`,
                        boxShadow: '0px 18.6667px 62.2222px rgba(0, 0, 0, 0.1)',
                        width: '1.875rem',
                        height: '1.875rem',
                        borderRadius: '50%',
                        mr: '-4px'
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F7F8FF',
                        border: `0.311111px solid ${theme.colors.secondary.main}`,
                        boxShadow: '0px 18.6667px 62.2222px rgba(0, 0, 0, 0.1)',
                        // width: xlDown ? 27 : 40,
                        // height: xlDown ? 27 : 40,
                        width: '1.875rem',
                        height: '1.875rem',
                        borderRadius: '50%',
                        mr: '-6px'
                      }}
                    >
                      <Box
                        // width={xlDown ? 7 : 11}
                        // height={xlDown ? 11 : 16}
                        width="1rem"
                        height="0.75rem"
                        component="img"
                        src={icons.profilePr}
                      />
                    </Box>
                  )}
                  <Typography fontSize={'0.875rem'} fontWeight={600}>
                    {email}
                  </Typography>
                </Box>
                {planTitle ? (
                  <Box>
                    <Chip
                      sx={{
                        '&.MuiChip-filled': {
                          background: planColors.get(planTitle),
                          borderRadius: '0.125rem',
                          height: '1.25rem'
                        },
                        '.MuiChip-label': {
                          textTransform: 'capitalize',
                          color: '#fff',
                          fontSize: '0.625rem',
                          fontWeight: 500,
                          lineHeight: '0.75rem'
                        }
                      }}
                      label={`${planTitle} Plan`}
                    />
                  </Box>
                ) : (
                  false
                )}
              </Box>
            </MenuUserBox>
          </MenuUserBoxWrapper>
          <Divider
            sx={{
              border: `0.5px solid ${theme.colors.secondary.main}`,
              opacity: 0.3,
              my: 1
            }}
          />
          <MenuNavBoxWrapper>
            <MenuNavBox>
              <List
                sx={{
                  '& .MuiListItem-root': {
                    paddingY: '0',
                    paddingX: '0rem'
                  }
                }}
                component="nav"
              >
                <ListItem>
                  <Link
                    sx={{
                      width: '100%',

                      px: '0.5rem',

                      fontSize: '0.875rem',
                      color: `${theme.colors.secondary.main}`
                    }}
                    underline="none"
                  >
                    <Box
                      width="0.875rem"
                      height="0.875rem"
                      component={'img'}
                      alt="hastag"
                      src={icons.hashtag}
                    />
                    <ListItemText
                      primary={`Public Key: ${formatAddress(
                        walletAddress,
                        4,
                        -2
                      )}`}
                    />

                    <IconButton
                      sx={{
                        fill: `${theme.colors.secondary.main}`,
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#E9E8EA'
                        },
                        mr: '-0.6rem'
                      }}
                      onClick={onClickCopyWalletAddress}
                    >
                      <Box
                        width="0.875rem"
                        height="0.875rem"
                        component={'img'}
                        alt="copy"
                        src={isCopied ? icons.copySuccess : icons.copySec}
                      />
                    </IconButton>
                  </Link>
                </ListItem>
                <ListItem onClick={() => setOpen(false)}>
                  <Link
                    sx={{
                      width: '100%',
                      p: '0.5rem',
                      borderRadius: '0.1875rem',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#E9E8EA'
                      },

                      fontSize: '0.875rem',
                      color: `${theme.colors.secondary.main}`
                    }}
                    underline="none"
                    target="_blank"
                    href={`${window.location.origin}/${toNavigate}/${walletAddress}`}
                  >
                    <Box
                      width="0.875rem"
                      height="0.875rem"
                      component={'img'}
                      alt="profile"
                      src={icons.profileSec}
                    />
                    <ListItemText primary="Public Profile" />
                  </Link>
                </ListItem>
                <ListItem onClick={() => setOpen(false)}>
                  <Link
                    sx={{
                      width: '100%',
                      p: '0.5rem',
                      borderRadius: '0.1875rem',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#E9E8EA'
                      },

                      fontSize: '0.875rem',
                      color: `${theme.colors.secondary.main}`
                    }}
                    underline="none"
                    component={RouterLink}
                    to="/dashboard/settings"
                  >
                    <Box
                      width="0.875rem"
                      height="0.875rem"
                      component={'img'}
                      alt="settings"
                      src={icons.setting}
                      sx={{
                        filter: 'brightness(0.5)'
                      }}
                    />
                    <ListItemText primary="Settings" />
                  </Link>
                </ListItem>

                <Divider
                  sx={{
                    border: `0.5px solid ${theme.colors.secondary.main}`,
                    opacity: 0.3,
                    my: 1
                  }}
                />

                <ListItem>
                  <Link
                    sx={{
                      width: '100%',
                      p: '0.5rem',
                      borderRadius: '0.1875rem',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#E9E8EA'
                      },

                      fontSize: '0.875rem',
                      color: `${theme.colors.secondary.main}`
                    }}
                    underline="none"
                    onClick={onLogOutClick}
                  >
                    <Box
                      width="0.875rem"
                      height="0.875rem"
                      component={'img'}
                      alt="sms"
                      src={icons.logoutOutline}
                      sx={{
                        filter: 'brightness(0.5)'
                      }}
                    />
                    <ListItemText primary="Log out" />
                  </Link>
                </ListItem>
              </List>
            </MenuNavBox>
          </MenuNavBoxWrapper>
        </MenuWrapper>
      </StyledPopover>
    </>
  );
}

export default HeaderUserBox;
