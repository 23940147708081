// @mui
import { Box, BoxProps, Stack, SxProps } from '@mui/material';
import { logos } from '../../assets/index';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  diplomadeBrand?: boolean;
  width?: number;
  white?: boolean;
  logoSx?: SxProps;
  brandSx?: SxProps;
}

export default function Logo({
  sx,
  diplomadeBrand = true,
  white,
  logoSx,
  brandSx
}: Props) {
  const logo = (
    <Box
      component="img"
      src={white ? logos.diplomadeLogoWhite : logos.diplomadeLogo}
      height="2.25rem"
      sx={{
        transition: 'width 0.5s ease-out',
        ...sx
      }}
    />
  );
  const logoWithBrand = (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap="0.55rem"
      sx={{
        transition: 'width 0.5s ease-out',
        ...sx
      }}
    >
      <Box
        component="img"
        src={white ? logos.diplomadeLogoWhite : logos.diplomadeLogo}
        height="2.25rem"
        sx={logoSx}
      />
      {diplomadeBrand && (
        <Box
          component="img"
          src={white ? logos.diplomadeBrandWhite : logos.diplomadeBrand}
          width="8.75rem"
          height="1.75rem"
          sx={brandSx}
        />
      )}
    </Stack>
  );

  return diplomadeBrand ? logoWithBrand : logo;
}
