// material
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CenteredGrid } from '../../components/Grid/CenteredGrid';
import { CenteredStack } from '../../components/Stack/CenteredStack';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  background: theme.palette.common.white,
  // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  borderTop: '0.5px solid rgba(0, 0, 0, 0.35)',
  color: theme.palette.primary.main
}));

const FooterBarStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '4rem'
}));

const ITEMS = [
  {
    title: 'About Diplomade',
    href: 'https://diplomade.com'
  },
  {
    title: 'Terms',
    href: ''
  },
  {
    title: 'Privacy',
    href: ''
  },
  {
    title: 'Support',
    href: 'https://diplomade.com'
  }
] as const;

type Item = typeof ITEMS[number];

export default function HeaderOnlyFooter() {
  const smUp = useResponsive('up', 'sm');
  return (
    <RootStyle>
      <FooterBarStyle>
        <CenteredStack>
          {/* <Grid item xs={12} md={8}>
            <Typography variant="body1" gutterBottom>
              <span>&#169;</span> 2022 <b>Diplomade</b> All right reserved
            </Typography>
          </Grid> */}
          <Stack direction={'row'}>
            {ITEMS.map((item: Item, i: number) => {
              const { title, href } = item;
              return (
                <CenteredStack direction="row" key={title}>
                  <Link
                    fontSize={'0.75rem'}
                    underline="none"
                    href={href}
                    target="_blank"
                  >
                    {title}
                  </Link>

                  {i !== ITEMS.length - 1 ? (
                    <Typography mx={'0.75rem'} fontSize={'0.75rem'}>
                      |
                    </Typography>
                  ) : null}
                </CenteredStack>
              );
            })}
          </Stack>
        </CenteredStack>
      </FooterBarStyle>
    </RootStyle>
  );
}
