import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import useLocalStorage from 'src/hooks/useStorage';

export default function AuthButtons() {
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');
  const [userEmail] = useLocalStorage('userEmail', '');
  return (
    <Stack direction="row" gap={3}>
      {!userEmail ? (
        <Button
          onClick={() => navigate('/register')}
          color={'primary'}
          variant="contained"
          size={smUp ? 'medium' : 'small'}
        >
          Create Account
        </Button>
      ) : (
        <Button
          onClick={() => navigate('/login')}
          color="primary"
          variant="contained"
          size={smUp ? 'medium' : 'small'}
        >
          {' '}
          Sign In
        </Button>
      )}
    </Stack>
  );
}
