export const icons = {
  badgeUser: require('./svg/icons/badge-icon-u.svg').default,
  badgeUserSec: require('./svg/icons/badge-icon-u-sec.svg').default,
  badgeIssuer: require('./svg/icons/badge-icon-i.svg').default,
  badgeIssuerSec: require('./svg/icons/badge-icon-i-sec.svg').default,
  badgePreviewStar: require('./icons/badge-preview-star.png'),
  badgePreviewBox: require('./icons/badge-preview-box.png'),
  overview: require('./svg/icons/overview.svg').default,
  overviewSec: require('./svg/icons/overview-sec.svg').default,
  add: require('./svg/icons/add.svg').default,
  addSquare: require('./svg/icons/add-square.svg').default,
  minus: require('./svg/icons/minus.svg').default,
  minusCircleRed: require('./svg/icons/minus-cirlce-red.svg').default,
  analytics: require('./icons/analytics.png'),
  global: require('./icons/global.png'),
  location: require('./icons/location.png'),
  profile2user: require('./icons/profile2user.png'),
  folderOpen: require('./icons/folder-open.png'),
  send: require('./icons/send.png'),
  connectingDots: require('./icons/connecting-dots.png'),
  transmitsquare: require('./svg/icons/transmitsquare.svg').default,
  star: require('./svg/icons/star.svg').default,
  crop: require('./svg/icons/crop.svg').default,
  eye: require('./svg/icons/eye.svg').default,
  eyeSlash: require('./svg/icons/eyeslash.svg').default,
  lock: require('./svg/icons/lock.svg').default,
  lockBlue: require('./svg/icons/lock-blue.svg').default,
  lockSecondary: require('./svg/icons/lock-sec.svg').default,
  unLock: require('./svg/icons/unlock.svg').default,
  profile: require('./svg/icons/profile.svg').default,
  profilePr: require('./svg/icons/profile-pr.svg').default,
  profileSec: require('./svg/icons/profile-sec.svg').default,
  profileAdd: require('./svg/icons/profile-add.svg').default,
  profileTick: require('./svg/icons/profile-tick.svg').default,
  profileDelete: require('./svg/icons/profile-delete.svg').default,
  setting: require('./svg/icons/setting.svg').default,
  settingSec: require('./svg/icons/setting-sec.svg').default,
  calendar: require('./svg/icons/calendar.svg').default,
  logOut: require('./icons/log-out.png'),
  arrowDown: require('./icons/arrow-down.png'),
  arrowLeft: require('./svg/icons/arrow-left.svg').default,
  sortArrows: require('./svg/icons/sort-arrows.svg').default,
  editPencilColored: require('./svg/icons/edit-pencil-c.svg').default,
  editPencil: require('./svg/icons/edit-pencil.svg').default,
  editCircle: require('./svg/icons/edit-circle.svg').default,
  trashColored: require('./svg/icons/trash-c.svg').default,
  trash: require('./svg/icons/trash.svg').default,
  linearTrash: require('./svg/icons/linear-trash.svg').default,
  checkmarkVerified: require('./icons/checkmark-verified.png'),
  verifiedColored: require('./svg/icons/verified-colored.svg').default,
  decline: require('./svg/icons/decline.svg').default,
  searchColored: require('./svg/icons/search-colored.svg').default,
  listItem: require('./icons/list-item.png'),
  link: require('./svg/icons/link.svg').default,
  listItem1: require('./icons/list-item-1.png'),
  link1: require('./icons/link-1.png'),
  logoutOutline: require('./svg/icons/logout-outline.svg').default,
  code: require('./icons/code.png'),

  loading: require('./icons/loading.png'),
  close: require('./svg/icons/close.svg').default,
  closeWhite: require('./svg/icons/close-white.svg').default,
  shieldCross: require('./svg/icons/shield-cross.svg').default,
  securityuser: require('./svg/icons/security-user.svg').default,
  copy: require('./icons/copy.png'),
  copySec: require('./svg/icons/copy-sec.svg').default,
  copyFull: require('./svg/icons/copy-full.svg').default,
  copyEmpty: require('./svg/icons/copy-empty.svg').default,
  copyEmptyWhite: require('./svg/icons/copy-empty-white.svg').default,
  copySuccess: require('./svg/icons/copy-success.svg').default,
  copySuccessWhite: require('./svg/icons/copy-success-white.svg').default,
  checkbox: require('src/assets/svg/icons/checkbox.svg').default,
  documentDownload: require('./svg/icons/document-download.svg').default,
  documentUpload: require('./svg/icons/document-upload.svg').default,
  warning2: require('./svg/icons/warning2.svg').default,
  warningSuccess: require('./icons/warning-success.png'),
  warningError: require('./icons/warning-error.png'),
  messageQuestion: require('./icons/messagequestion.png'),
  boxesColored: require('./icons/boxes-c.png'),
  boxAddBc: require('./icons/box-add-bc.png'),
  expandMore: require('./icons/expand-more.png'),
  customerFeedback: require('./icons/customer-feedback.png'),
  share: require('./svg/icons/share.svg').default,
  galleryImport: require('./svg/icons/gallery-import.svg').default,
  maximize: require('./svg/icons/maximize.svg').default,
  minimize: require('./svg/icons/minimize.svg').default,
  rotateRight: require('./svg/icons/rotate-right.svg').default,
  rotateLeft: require('./svg/icons/rotate-left.svg').default,
  tick: require('./svg/icons/tick.svg').default,
  tickWhite: require('./svg/icons/tick-white.svg').default,
  // socials
  fbOutline: require('./svg/icons/facebook-outline.svg').default,
  inOutline: require('./svg/icons/linkedin-outline.svg').default,
  inOutlineSec: require('./svg/icons/linkedin-outline-sec.svg').default,
  twOutline: require('./svg/icons/twitter-outline.svg').default,
  twOutlineSec: require('./svg/icons/twitter-outline-sec.svg').default,
  githubOutline: require('./svg/icons/github-outline.svg').default,
  githubOutlineSec: require('./svg/icons/github-outline-sec.svg').default,
  hambergerMenu: require('./svg/icons/hambergermenu.svg').default,
  hambergerMenuPr: require('./svg/icons/hambergermenu-pr.svg').default,
  envelopeBlue: require('./svg/icons/envelope-blue.svg').default,
  export: require('./svg/icons/export.svg').default,
  hashtag: require('./svg/icons/hashtag.svg').default,
  smsSecondary: require('./svg/icons/sms-sec.svg').default,
  notificationSec: require('./svg/icons/notification-sec.svg').default,
  sendSquareContainedSec: require('./svg/icons/send-square-contained-sec.svg')
    .default,
  starContainedSec: require('./svg/icons/star-contained-sec.svg').default,
  medalStarContainedSec: require('./svg/icons/medal-star-contained-sec.svg')
    .default,
  verifiedBadge: require('./svg/icons/verified-badge.svg').default,
  verifyContainedSec: require('./svg/icons/verify-contained-sec.svg').default,
  verifyWhite: require('./svg/icons/verify-white.svg').default,
  securitySafe: require('./svg/icons/security-safe.svg').default,
  oceanProtocol: require('./svg/icons/ocean-protocol.svg').default,
  boxTickWhite: require('./svg/icons/box-tick-white.svg').default,
  emptyWalletTickSec: require('./svg/icons/empty-wallet-tick-sec.svg').default,
  heartSec: require('./svg/icons/heart-sec.svg').default,
  likeSec: require('./svg/icons/like-sec.svg').default
};

export const placeholders = {
  profile: require('./images/placeholders/profile.png'),
  certificate: require('./svg/placeholders/certificate.svg').default,
  profileWhite: require('./images/placeholders/profile-white.png'),
  profileWhiteUser: require('./svg/placeholders/profile-white-user.svg')
    .default,
  profileWhiteIssuer: require('./svg/placeholders/profile-white-issuer.svg')
    .default,
  placeholderLogo: require('./images/placeholders/placeholder-logo.png'),
  dashedDividerVr: require('./images/placeholders/dashed-divider-vr.png'),
  noBadge: require('./svg/placeholders/no-badge.svg').default,
  badge: require('./images/placeholders/badge.png'),
  badgeDesignTemplate: require('./images/placeholders/badge-design-template.png'),
  completeProfile: require('./images/placeholders/complete-profile.png'),
  teamWork: require('./svg/placeholders/teamwork.svg').default,
  blocks: require('./svg/placeholders/blocks.svg').default,
  mining: require('./svg/placeholders/mining.svg').default,
  key: require('./svg/placeholders/key.svg').default,
  lock: require('./svg/placeholders/lock.svg').default,
  completeSecurity: require('./svg/placeholders/complete-security.svg').default,
  liveCheckSuccess: require('./svg/placeholders/livecheck-success.svg').default,
  liveCheckFail: require('./svg/placeholders/livecheck-fail.svg').default,
  wingRock: require('./svg/placeholders/wingrock.svg').default,
  verifiedUserTick: require('./svg/placeholders/verified-user-tick.svg')
    .default,
  noProfileImage: require('./svg/placeholders/no-profile-image.svg').default,
  verifyIdentityColored:
    require('./svg/placeholders/verify-identity-colored.svg').default,
  verifiedIdentityColored:
    require('./svg/placeholders/verified-identity-colored.svg').default
};

export const logos = {
  diplomadeLogo: require('./svg/logo/diplomade-logo.svg').default,
  diplomadeLogoSec: require('./svg/logo/diplomade-logo-sec.svg').default,
  diplomadeBrand: require('./svg/logo/diplomade-brand.svg').default,
  diplomadeBrandWhite: require('./svg/logo/diplomade-brand-white.svg').default,
  diplomadeLogoWhite: require('./svg/logo/diplomade-logo-white.svg').default,
  linkedinInitialColored: require('./images/logo/linkedIn-initials-c.png'),
  twitter: require('./images/logo/twitter-c.png'),
  github: require('./images/logo/github-c.png'),
  google: require('./svg/logo/google.svg').default
};
