import { icons } from 'src/assets';
import { Metadata } from './commons';

export interface BadgeResponse {
  metadata?: Metadata;
  totalItemCount?: number;
  paginatedItems?: Badge[] | [];
  grouped?: GrouppedBadges;
}
export interface GrouppedBadges {
  [key: string]: Badge[];
}

export type EarningCriterias = {
  description: string;
  url: string;
};

export interface Badge {
  badgeId?: string;
  issueId?: string;
  id?: number;
  createTxHash: string;
  polygonTxHash?: string;
  polygonIssuerSignature?: string;
  polygonStatus?: string;
  isOnPolygon: boolean;
  creationDate?: string;
  issueTxHash?: string;
  issueDate?: string;
  skills: string[];
  signature: string;
  issuerAddress: string;
  issuerName: string;
  imageUrl: string;
  description: string;
  title: string;
  recipientAddress?: string;
  recipientSignature?: string;
  issuerSignature?: string;
  certificantSignature?: string | null;
  detailsUrl?: string | null;
  earningCriterias?: EarningCriterias[];
  successIssueCount?: string;
  status: string;
  isPublic?: boolean;
  expiresAt?: string;
  earnThisCredentialUrl?: string;
}

export interface IssuedBadge {
  issueId: string;
  issueTxHash: string;
  issueDate: string;
  expiresAt?: string;
  settlementDate?: string;
  title: string;
  skills: string[];
  description: string;
  imageUrl: string;
  earningCriterias: EarningCriterias[];
  detailsUrl: string;
  issuerAddress: string;
  issuerName: string;
  recipientAddress: string;
  recipientName: string;
  issuerProfileImageUrl?: string;
  isOnPolygon: boolean;
}

export interface ImportedBadgeResponse {
  metadata?: Metadata;
  totalItemCount?: number;
  paginatedItems?: ImportedBadge[] | [];
}
export interface ImportedBadge {
  id?: string;
  certificateType: string;
  title: string;
  description: string;
  institutionName: string;
  recipientName: string;
  issuedAt: string | null;
  expiredAt: string | null;
  emailVerifiedAt: string | null;
  emailDomain: string;
  email: string;
  polygonTxHash: string;
  isOnPolygon: boolean;
  polygonStatus?: string;
  imageUrl: string;
  compressedImageUrl: string;
  createdAt: string;
  recipientAddress: string;
  isPublic: boolean;
}

export interface BadgeIssuedUser {
  issueId: string;
  status: string;
  issueTxHash: string | null;
  issueDate: string | null;
  settlementDate: string | null;
  recipientAddress: string | null;
  recipientEmail: string;
}

export interface SignBadgeReqeustParams {
  issues: SignedBadge[];
}
export interface SignedBadge {
  issueId: string;
  signature?: string;
  recipientSignature?: string;
}

export enum BadgeStatus {
  READY_TO_BE_SUBMITTED = 'READY_TO_BE_SUBMITTED',
  AWAIT_CHAIN = 'AWAIT_CHAIN',
  SUCCESS = 'SUCCESS'
}

export enum IssueStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  AWAIT_SIGNATURES = 'AWAIT_SIGNATURES',
  DECLINED = 'DECLINED',
  AWAIT_REGISTRATION = 'AWAIT_REGISTRATION',
  AWAIT_ISSUER = 'AWAIT_ISSUER',
  AWAIT_CHAIN = 'AWAIT_CHAIN',
  READY_TO_BE_SUBMITTED = 'READY_TO_BE_SUBMITTED',
  CANCELED = 'CANCELED'
}

export const badgeStatusesMap = new Map<string, string[]>([
  ['SUCCESS', ['Accepted', '#241C2D']],
  ['AWAIT_ISSUER', ['Pending', '#6A769F']],
  ['DECLINED', ['Declined', '#FF2E00']],
  ['READY_TO_BE_SUBMITTED', ['Pending', '#6A769F']],
  ['AWAIT_CHAIN', ['Pending', '#6A769F']],
  ['AWAIT_SIGNATURES', ['Acceptance', '#FFE7AD']],
  ['AWAIT_REGISTRATION', ['Registration', '#FFC947']],
  ['CANCELED', ['Canceled', '#FF2E00']]
]);
