import {
  differenceInDays,
  differenceInHours,
  differenceInWeeks,
  isBefore
} from 'date-fns';

export const calculateTimeDifference = (date: string) => {
  const isPreviousDate = isBefore(new Date(date), new Date());

  let diffInDays = differenceInDays(new Date(date), new Date());
  const positiveDiffInDays = isPreviousDate ? diffInDays * -1 : diffInDays;

  if (positiveDiffInDays === 0) {
    const hoursDifference = differenceInHours(new Date(date), new Date());
    return handleTimeTag(isPreviousDate, hoursDifference, 'h');
  } else if (positiveDiffInDays < 7) {
    return handleTimeTag(isPreviousDate, diffInDays, 'd');
  } else if (positiveDiffInDays < 30) {
    const weeksDifference = differenceInWeeks(new Date(date), new Date());
    return handleTimeTag(isPreviousDate, weeksDifference, 'w');
  } else {
    const monthsDifference = differenceInWeeks(new Date(date), new Date());
    return handleTimeTag(isPreviousDate, monthsDifference, 'm');
  }
};

const handleTimeTag = (
  isPreviousDate: boolean,
  difference: number,
  tag: string
) =>
  isPreviousDate
    ? `${difference * -1}${tag} ago`
    : `in next ${difference}${tag}`;
