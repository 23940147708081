import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';

export const CHAIN_CONFIG = {
  amoy: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://rpc-amoy.polygon.technology/',
    blockExplorer: 'https://amoy.polygonscan.com/',
    chainId: '0x13882', // hex of 80002
    displayName: 'Amoy',
    ticker: 'matic',
    tickerName: 'Matic'
  } as CustomChainConfig,
  polygon: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://polygon-rpc.com',
    blockExplorer: 'https://polygonscan.com',
    chainId: '0x89', //hex of 137
    displayName: 'Polygon Mainnet',
    ticker: 'matic',
    tickerName: 'Matic'
  } as CustomChainConfig,
  fuji: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xA869', // hex of 43113
    rpcTarget: 'https://api.avax-test.network/ext/bc/C/rpc',
    displayName: 'Avalanche C-Chain Testnet',
    blockExplorer: 'https://testnet.avascan.info',
    ticker: 'AVAX',
    tickerName: 'AVAX'
  } as CustomChainConfig,
  avax: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xA86A', // hex of 43114
    rpcTarget: 'https://api.avax.network/ext/bc/C/rpc',
    displayName: 'Avalanche C-Chain',
    blockExplorer: 'https://avascan.info',
    ticker: 'AVAX',
    tickerName: 'AVAX'
  } as CustomChainConfig
} as const;

export enum CHAIN {
  AVAX = 'avax',
  AMOY = 'amoy',
  POLYGON = 'polygon',
  FUJI = 'fuji'
}

export const CHAIN_DATA =
  process.env.REACT_APP_ENV !== 'production'
    ? CHAIN_CONFIG[CHAIN.AMOY]
    : CHAIN_CONFIG[CHAIN.POLYGON];
