import { forwardRef, useContext, useState } from 'react';

import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  Dialog,
  Slide,
  useTheme,
  List
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import { Web3AuthContext } from 'src/contexts/Web3Auth';
import Logo from 'src/components/Logo';
import HeaderUserBox from './Userbox';
import HeaderMenu from './Menu';
import HeaderNotifications from './Buttons/Notifications';
import AuthButtons from './Buttons/Auth/index';
import UserRole from './Role';
import { CenteredStack } from 'src/components/Stack/CenteredStack';
import { icons } from 'src/assets';
import { TransitionProps } from '@mui/material/transitions';

const RootStyle = styled(AppBar)(({ theme }) => ({
  background: theme.header.background,
  boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.10)',
  transition: 'padding 0.5s ease-out',
  padding: '0px 1.5rem'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  transition: 'height 0.5s ease-out',
  height: theme.header.height,
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between'
  }
  // [theme.breakpoints.down('sm')]: {
  //   height: theme.header.heightSm
  // }
}));

export default function Header() {
  const { user } = useContext(Web3AuthContext);
  const smUp = useResponsive('up', 'sm');
  const theme = useTheme();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <RootStyle position="fixed">
      <ToolbarStyle>
        <Link
          underline="none"
          component={RouterLink}
          to={{
            pathname: `/`
          }}
        >
          {smUp ? (
            <Logo />
          ) : (
            <Logo
              sx={{
                gap: '0.2rem'
              }}
              logoSx={{ width: '1.75rem', height: '1.75rem' }}
              brandSx={{ width: '7.5rem', height: '1.5rem' }}
            />
          )}
        </Link>

        {user ? (
          <HeaderMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        ) : (
          false
        )}

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {user ? (
            <>
              <CenteredStack
                display={{ xs: 'none', sm: 'flex' }}
                direction="row"
              >
                {/* <UserRole /> */}
                <HeaderNotifications />

                <HeaderUserBox />
              </CenteredStack>
              <Stack display={{ sm: 'none' }}>
                <IconButton color="primary" onClick={toggleMenu}>
                  <Box
                    component={'img'}
                    width={24}
                    height={24}
                    src={icons.hambergerMenuPr}
                  />
                </IconButton>
              </Stack>
            </>
          ) : (
            <AuthButtons />
          )}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
