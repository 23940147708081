import { alpha, createTheme, lighten, darken, Typography } from '@mui/material';
import '@mui/lab/themeAugmentation';

import PlusJakartaSans from '../../assets/fonts/PlusJakartaSans-Regular.woff2';
import NotoSansVariableFont from '../../assets/fonts/NotoSans-VariableFont.ttf';

export const themeColors = {
  primary: '#241C2D',
  primaryLight: '#504957',
  primaryLighter: '#E9E8EA',
  secondary: '#7C7781',
  secondaryLight: '#A7A4AB',
  secondaryLighter: '#D3D2D5',
  tertiary: '#8797D2',
  tertiaryLight: '#E7EAF6',
  tertiaryLighter: '#F3F5FB',
  buttonPrimary: '#241C2D',
  buttonSecondary: '#7AB8D6',
  buttonDisabled: '#7C7781',
  textTertiary: '#D3D2D5',
  background: '#F6F6F6',
  success: '#42DEB5',
  warning: '#FFC947',
  error: '#FC4B37',
  info: '#185ADB',
  black: '#223354',
  white: '#ffffff',
  green1: '#00C893'
};

// const appMaxWidth: number = 1850;

const colors = {
  gradients: {
    blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
    blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
    blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
    blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
    blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
    orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
    orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
    orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
    purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
    pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
    pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
    green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
    green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
    black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
    black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
  },
  shadows: {
    success:
      '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error:
      '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary:
      '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
    warning:
      '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
    cardSm:
      '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    cardLg:
      '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)'
  },
  layout: {
    general: {
      bodyBg: themeColors.background
    },
    sidebar: {
      background: themeColors.primary,
      textColor: themeColors.white,
      dividerBg: '#939DA5',
      menuItemColor: '#242E6F',
      menuItemColorActive: themeColors.white,
      menuItemBg: themeColors.primary,
      menuItemBgActive: themeColors.primaryLight,
      menuItemIconColor: themeColors.white,
      menuItemIconColorActive: themeColors.white,
      menuItemHeadingColor: themeColors.white
    },
    header: {
      background: themeColors.background,
      textColor: themeColors.primary,
      textColorActive: themeColors.white,
      dividerBg: themeColors.primaryLight,
      menuItemColor: themeColors.primary,
      menuItemColorActive: themeColors.white,
      menuItemBg: themeColors.white,
      menuItemBgActive: themeColors.primary,
      menuItemIconColor: themeColors.white,
      menuItemIconColorActive: themeColors.white,
      menuItemHeadingColor: themeColors.white
    }
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black
    }
  },

  secondary: {
    lighter: themeColors.secondaryLighter,
    light: themeColors.secondaryLight,
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2)
  },
  primary: {
    lighter: themeColors.primaryLighter,
    light: themeColors.primaryLight,
    main: themeColors.primary,
    dark: darken(themeColors.primary, 0.2)
  },
  tertiary: {
    lighter: themeColors.tertiaryLight,
    light: themeColors.tertiaryLight,
    main: themeColors.tertiary,
    dark: darken(themeColors.tertiary, 0.2)
  },
  success: {
    lighter: lighten(themeColors.success, 0.85),
    light: lighten(themeColors.success, 0.3),
    main: themeColors.success,
    dark: darken(themeColors.success, 0.2)
  },
  warning: {
    lighter: lighten(themeColors.warning, 0.85),
    light: lighten(themeColors.warning, 0.3),
    main: themeColors.warning,
    dark: darken(themeColors.warning, 0.2)
  },
  error: {
    lighter: lighten(themeColors.error, 0.85),
    light: lighten(themeColors.error, 0.3),
    main: themeColors.error,
    dark: darken(themeColors.error, 0.2)
  },
  info: {
    lighter: lighten(themeColors.info, 0.85),
    light: lighten(themeColors.info, 0.3),
    main: themeColors.info,
    dark: darken(themeColors.info, 0.2)
  }
};
const {
  palette: { augmentColor }
} = createTheme();
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
export const DiplomadeTheme = createTheme({
  // direction: i18n.dir(),
  colors: {
    gradients: {
      blue1: colors.gradients.blue1,
      blue2: colors.gradients.blue2,
      blue3: colors.gradients.blue3,
      blue4: colors.gradients.blue4,
      blue5: colors.gradients.blue5,
      orange1: colors.gradients.orange1,
      orange2: colors.gradients.orange2,
      orange3: colors.gradients.orange3,
      purple1: colors.gradients.purple1,
      purple3: colors.gradients.purple3,
      pink1: colors.gradients.pink1,
      pink2: colors.gradients.pink2,
      green1: colors.gradients.green1,
      green2: colors.gradients.green2,
      black1: colors.gradients.black1,
      black2: colors.gradients.black2
    },
    shadows: {
      success: colors.shadows.success,
      error: colors.shadows.error,
      primary: colors.shadows.primary,
      info: colors.shadows.info,
      warning: colors.shadows.warning
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      trueWhite: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black
      }
    },
    secondary: {
      lighter: themeColors.secondaryLighter,
      light: themeColors.secondaryLight,
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2)
    },
    primary: {
      lighter: themeColors.primaryLighter,
      light: themeColors.primaryLight,
      main: themeColors.primary,
      dark: darken(themeColors.primary, 0.2)
    },

    success: {
      lighter: alpha(themeColors.success, 0.1),
      light: lighten(themeColors.success, 0.3),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      lighter: alpha(themeColors.warning, 0.1),
      light: lighten(themeColors.warning, 0.3),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      lighter: alpha(themeColors.error, 0.1),
      light: lighten(themeColors.error, 0.3),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      lighter: alpha(themeColors.info, 0.1),
      light: lighten(themeColors.info, 0.3),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    }
  },
  general: {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '6px',
    borderRadius: '10px',
    borderRadiusLg: '12px',
    borderRadiusXl: '16px'
    // appMaxWidth: appMaxWidth
  },
  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    boxShadow:
      '2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)',
    widthXl: '18vw',
    widthLg: '20vw',
    widthMd: '25vw',
    widthSm: '50vw'
  },
  header: {
    height: '5rem',
    heightXl: '120px',
    heightLg: '80px',
    heightSm: '60px',
    boxShadow: colors.shadows.cardSm,
    background: colors.layout.header.background,
    textColor: colors.layout.header.textColor,
    textColorActive: colors.layout.header.textColorActive,
    dividerBg: colors.layout.header.dividerBg,
    menuItemColor: colors.layout.header.menuItemColor,
    menuItemColorActive: colors.layout.header.menuItemColorActive,
    menuItemBg: colors.layout.header.menuItemBg,
    menuItemBgActive: colors.layout.header.menuItemBgActive,
    menuItemIconColor: colors.layout.header.menuItemIconColor,
    menuItemIconColorActive: colors.layout.header.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.header.menuItemHeadingColor
  },
  spacing: 10,
  palette: {
    common: {
      black: colors.alpha.black[100],
      white: colors.alpha.white[100]
    },
    mode: 'light',
    primary: { ...colors.primary },
    secondary: { ...colors.secondary },
    tertiary: { ...colors.tertiary },
    error: { ...colors.error },
    success: {
      ...colors.success
    },
    info: {
      ...colors.info
    },
    warning: {
      ...colors.warning
    },
    text: {
      primary: themeColors.primary,
      secondary: themeColors.secondary,
      disabled: themeColors.textTertiary
    },
    button: {
      primary: themeColors.buttonPrimary,
      secondary: themeColors.buttonSecondary,
      disabled: themeColors.buttonDisabled
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg
    },
    action: {
      active: colors.primary.light,
      hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.black[50],
      disabledBackground: themeColors.buttonDisabled,
      disabledOpacity: 1,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12
    },
    tonalOffset: 0.5
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800
    }
  },
  typography: {
    fontFamily: [
      'Plus Jakarta Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem'
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.75rem'
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.5rem'
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.25rem'
    },
    h5: {
      fontWeight: 700,
      fontSize: '1rem'
    },
    h6: {
      fontWeight: 700,
      fontSize: '0.75rem'
    },
    body1: {
      fontSize: '1.25rem'
    },
    body2: {
      fontSize: '1rem'
    },
    button: {
      fontWeight: 500,
      fontSize: '1rem'
    },
    caption: {
      fontSize: '1rem',
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontSize: '0.75rem' //12px
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.5rem' //8 px
    },
    overline: {
      fontSize: '1.25rem',
      fontWeight: 700,
      textTransform: 'uppercase'
    }
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            padding: '32px 48px',
            background: '#F2F3FA',
            alignItems: 'center',
            textAlign: 'center',
            maxWidth: '50vw',
            // width: '100%',
            '@media (max-width: 1200px)': {
              maxWidth: '600px'
            }
          },
          '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: '#000',
          opacity: '0.5 !important'

          // '&.MuiBackdrop-invisible': {
          //   backgroundColor: 'transparent',
          //   backdropFilter: 'blur(2px)'
          // }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 0,
          marginRight: 8,
          fontWeight: 'bold',
          fontSize: '0.75rem',
          lineHeight: '0.9rem'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face ': {
          fontFamily: 'Plus Jakarta Sans',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 400,
          src: `local('Plus Jakarta Sans'), local('PlusJakartaSans-Regular'), url(${PlusJakartaSans}) format('woff2')`,
          unicodeRange:
            'U + 0000 - 00FF, U+ 0131, U+ 0152 - 0153, U+ 02BB- 02BC, U+ 02C6, U + 02DA, U + 02DC, U + 2000 - 206F, U + 2074, U + 20AC, U + 2122, U + 2191, U + 2193, U + 2212, U + 2215, U + FEFF'
        },

        fontStyle: 'normal',
        'html, body': {
          width: '100%',
          height: '100%',
          fontSize: '16px',
          '@media (min-width: 600px)': {
            fontSize: 'clamp(8px,1.34vw,12px)'
          },
          '@media (min-width: 900px)': {
            fontSize: 'clamp(12px,1.34vw,16px)'
          },
          '@media (min-width: 1200px)': {
            fontSize: 'clamp(16px,1.34vw,20px)'
          },
          '@media (min-width: 1500px)': {
            fontSize: 'clamp(20px,1.34vw,24px)'
          },
          '@media (min-width: 1800px)': {
            fontSize: 'clamp(24px,1.34vw,28px)'
          }
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          flex: 1
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
          // MozOsxFontSmoothing: 'grayscale',
          // WebkitFontSmoothing: 'antialiased'
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column'
        },
        '#nprogress': {
          pointerEvents: 'none'
        },
        '#nprogress .bar': {
          background: colors.primary.lighter
        },
        '#nprogress .spinner-icon': {
          borderTopColor: colors.primary.lighter,
          borderLeftColor: colors.primary.lighter
        },
        '#nprogress .peg': {
          boxShadow:
            '0 0 15px ' +
            colors.primary.lighter +
            ', 0 0 8px' +
            colors.primary.light
        },
        ':root': {
          '--swiper-theme-color': colors.primary.main
        },
        code: {
          background: colors.info.lighter,
          color: colors.info.dark,
          borderRadius: 4,
          padding: 4
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0
          }
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)'
          },
          '100%': {
            transform: 'translate(3%, 3%)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.alpha.black[50]
        },
        icon: {
          top: '45%',
          width: '0.75rem'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: colors.alpha.trueWhite[100],
          border: `0.5px ${colors.secondary.lighter} `,
          borderRadius: '0.1875rem',
          padding: '0.6rem 0.75rem',

          '& .MuiInputBase-input': {
            padding: 0,
            fontSize: '0.75rem',
            lineHeight: '0.9rem',
            fontWeight: 400
          },

          '& .MuiInputAdornment-root': {
            paddingLeft: 5
          },
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 6
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
          lineHeight: '40px',
          fontSize: 13,
          background: colors.alpha.black[5],
          color: colors.alpha.black[70]
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5
        },
        title: {
          fontSize: 15
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '1.25rem'
          }
        }
      }
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.75rem'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.secondary.main,
          '&.Mui-checked': {
            color: colors.primary.main
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: colors.secondary.main
          },
          svg: {
            '&[font-size=small]': {
              fontSize: 20
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: '0.5px solid rgba(106, 118, 159, 0.6)',
          borderRadius: 5,
          height: 30,
          fontSize: '1rem'
        },
        colorSecondary: {
          background: colors.alpha.black[5],
          color: colors.alpha.black[100],

          '&:hover': {
            background: colors.alpha.black[10]
          }
        },
        deleteIcon: {
          color: colors.error.light,

          '&:hover': {
            color: colors.error.main
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: 0
          },
          '&::before': {
            display: 'none'
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 'bold'
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.white[100]
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center'
        },
        avatar: {
          background: colors.alpha.black[10],
          fontSize: 13,
          color: colors.alpha.black[70],
          fontWeight: 'bold',

          '&:first-of-type': {
            border: 0,
            background: 'transparent'
          }
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: 'bold',
          transition: 'all .2s'
        },
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.primary
          },
          '&.MuiButtonBase-root:hover': {
            background: colors.alpha.black[5]
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary.main
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          borderRadius: '0.1875rem',

          '.MuiSvgIcon-root': {
            transition: 'all .2s'
          },
          '&.MuiButton-outlined': {
            color: colors.primary.main
          }
        },
        endIcon: {
          marginRight: -8
        },
        containedPrimary: {
          backgroundColor: themeColors.buttonPrimary
        },
        outlinedPrimary: {
          border: '1px solid ' + themeColors.buttonPrimary
        },
        containedSecondary: {
          backgroundColor: themeColors.buttonSecondary,
          color: colors.alpha.white[100]
        },
        outlinedSecondary: {
          backgroundColor: colors.alpha.white[100],
          border: '1px solid ' + themeColors.buttonSecondary,
          '&:hover, &.MuiSelected': {
            backgroundColor: colors.alpha.black[5],
            color: themeColors.buttonSecondary
          }
        },

        sizeSmall: {
          padding: ' 0rem 0.62rem',
          fontSize: '0.625rem',
          height: '1.5rem',
          lineHeight: 1.5
        },
        sizeMedium: {
          padding: '0rem 0.75rem',
          fontSize: '0.75rem',
          // minWidth: '8.375rem',
          height: '2rem'
        },
        sizeLarge: {
          fontSize: '0.875rem',
          padding: '0rem 0.875rem',
          height: '2.5rem'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false
      },
      styleOverrides: {
        root: {
          borderRadius: 6
        }
      }
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          color: colors.primary.main,
          background: colors.alpha.white[100],
          transition: 'all .2s',

          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.white[100],
            background: colors.primary.main
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 8,

          '& .MuiTouchRipple-root': {
            borderRadius: 8
          }
        },
        sizeSmall: {
          padding: 4
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-root': {
            opacity: 0.3
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.alpha.black[10],
          border: 0,
          height: 1
        },
        vertical: {
          height: 'auto',
          width: 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto'
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%'
          }
        },
        withChildren: {
          '&:before, &:after': {
            border: 0
          }
        },
        wrapper: {
          background: colors.layout.general.bodyBg,
          fontWeight: 'bold',
          height: 24,
          lineHeight: '24px',
          marginTop: -12,
          color: 'inherit',
          textTransform: 'uppercase'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0
        },
        elevation0: {
          boxShadow: 'none'
        },
        elevation: {
          boxShadow: colors.shadows.card
        },
        elevation2: {
          boxShadow: colors.shadows.cardSm
        },
        elevation24: {
          boxShadow: colors.shadows.cardLg
        },
        outlined: {
          boxShadow: colors.shadows.card
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: colors.secondary.lighter,
          borderRadius: 4,
          height: 6
        },
        barColorPrimary: {
          background: colors.primary.main
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-thumb': {
            backgroundColor: colors.primary.main //color of thumbs
          },
          '& .MuiSlider-track': {
            backgroundColor: colors.primary.main,
            borderColor: 'transparent'
          },

          '& .MuiSlider-rail': {
            // slider outside  teh area between thumbs
            color: colors.primary.main,
            opacity: 1
          },
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none'
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 6,
            borderColor: colors.primary.main,
            color: colors.primary.main,
            background: colors.alpha.white[100],
            fontSize: '1rem'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiListItem-button': {
            transition: 'all .2s',

            '& > .MuiSvgIcon-root': {
              minWidth: 34
            },

            '& .MuiTouchRipple-root': {
              opacity: 0.2
            }
          }
        },

        padding: {
          '& .MuiListItem-button': {
            borderRadius: 6,
            margin: '1px 0'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 48,
          minHeight: 48,
          overflow: 'visible',
          '@media (max-width: 600px)': {
            height: 38,
            minHeight: 38
          }
        },
        scroller: {},
        indicator: {
          height: 4,
          marginBottom: 0,
          borderRadius: 4,
          backgroundColor: themeColors.primary
        },
        scrollableX: {
          overflow: 'visible !important'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: 38,
          minHeight: 38,
          borderRadius: 6,
          transition: 'color .2s',
          textTransform: 'capitalize',
          fontSize: '1rem',
          fontWeight: 600,
          color: colors.secondary.light,

          '&.MuiButtonBase-root': {
            minWidth: 'auto'
            // paddingRight: 20,
            // marginRight: 15
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: colors.primary.main,
            zIndex: 5
          },
          '&:hover': {
            color: colors.primary.main
          },
          '@media (max-width: 600px)': {
            '&.MuiButtonBase-root': {
              padding: 0

              // paddingRight: 0,
              // marginRight: 0
            }
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: 12
        },
        list: {
          padding: 12,

          '& .MuiMenuItem-root.MuiButtonBase-root': {
            fontSize: 14,
            marginTop: 1,
            marginBottom: 1,
            transition: 'all .2s',
            color: colors.alpha.black[70],

            '& .MuiTouchRipple-root': {
              opacity: 0.2
            },

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: alpha(colors.primary.lighter, 0.4)
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'transparent',
          transition: 'all .2s',

          '&:hover, &:active, &.active, &.Mui-selected': {
            color: colors.alpha.black[100],
            background: alpha(colors.primary.lighter, 0.4)
          },
          '&.Mui-selected:hover': {
            background: alpha(colors.primary.lighter, 0.4)
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            color: colors.secondary.main,

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: lighten(colors.primary.lighter, 0.5)
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
            {
              right: 14
            }
        },
        clearIndicator: {
          background: colors.error.lighter,
          color: colors.error.main,
          marginRight: 8,

          '&:hover': {
            background: colors.error.lighter,
            color: colors.error.dark
          }
        },
        popupIndicator: {
          color: colors.alpha.black[50],

          '&:hover': {
            background: colors.primary.lighter,
            color: colors.primary.main
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8
          }
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: colors.alpha.black[5]
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: colors.alpha.black[5]
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.alpha.black[10],
          fontSize: 14
        },
        head: {
          textTransform: 'uppercase',
          fontSize: 13,
          fontWeight: 'bold',
          color: colors.alpha.black[70]
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: 14
        },
        standardInfo: {
          color: colors.info.main
        },
        action: {
          color: colors.alpha.black[70]
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          left: -6
        },
        outlined: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
        },
        outlinedPrimary: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '100%',
          top: 0,
          borderRadius: 50,
          backgroundColor: colors.alpha.black[10]
        }
      }
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '8px 0',

          '&:before': {
            display: 'none'
          }
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `${colors.primary.main} `,
          padding: '8px 16px',
          fontSize: 13
        },
        arrow: {
          color: `${colors.primary.main} `
        }
      }
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 44,
          height: 24,
          padding: 0,
          overflow: 'visible',
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '500ms',
            '&.Mui-checked': {
              transform: 'translateX(20px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: themeColors.primary,
                opacity: 1,
                border: 0
              },
              '&.Mui-disabled + .MuiSwitch-track': {},
              '& .MuiSwitch-thumb': {
                backgroundColor: themeColors.white
              }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: themeColors.secondary
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3
            }
          }
        },
        thumb: {
          width: 20,
          height: 20,
          border: '1px solid ' + colors.alpha.black[30],
          boxShadow:
            '0px 9px 14px ' +
            colors.alpha.black[10] +
            ', 0px 2px 2px ' +
            colors.alpha.black[10]
        },
        track: {
          borderRadius: 26 / 2,
          border: ' 0.666667px solid #7C7781',
          backgroundColor: `#E9E8EA`,
          opacity: 1
        },
        colorPrimary: {
          '& .MuiSwitch-thumb': {
            backgroundColor: colors.alpha.trueWhite
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.alpha.trueWhite
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: themeColors.textTertiary,
            borderRadius: '50%'
          },
          '& .MuiSvgIcon-root.Mui-completed': {
            color: themeColors.textTertiary
          },
          '& .MuiSvgIcon-root.Mui-active': {
            color: colors.secondary.main
          },
          '& .MuiStepLabel-root .MuiStepIcon-text': {
            fill: colors.secondary.main, // circle's number (ACTIVE)
            fontSize: '0.625rem',
            fontWeight: 600,
            lineHeight: '0.75rem'
          },
          '& .Mui-active .MuiStepIcon-text': {
            fill: colors.alpha.trueWhite[100]
          },
          '& .MuiStepLabel-labelContainer': {
            width: '80%'
          },
          '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            marginTop: '0.5rem'
          },

          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
            color: colors.secondary.main // Just text label (COMPLETED)
          },
          '& .MuiStepConnector-line': {
            borderColor: themeColors.textTertiary,
            padding: '0px',
            margin: 0
          },
          '& .MuiStepConnector-root': {
            display: 'absolute',
            top: '13px',
            zIndex: '-1',
            left: 'calc(-50% + 2px)',
            right: 'calc(50% + 2px)'
          }
        }
      }
    },

    MuiStepConnector: {
      styleOverrides: {
        root: {
          // position: 'absolute',
          // top: '9px',
          // left: 'calc(-50% + 11px)',
          // right: 'calc(50% + 11px)'
        }
      }
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div'
        }
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4
        },
        paragraph: {
          fontSize: '1rem',
          lineHeight: 1.5
        }
      }
    }
  },
  shape: {
    borderRadius: 6
  }
});
