const env = process.env;

const ENV = {
  BASE_URL: env.REACT_APP_BASE_URL_DEV,

  COGNITO_REGION: env.REACT_APP_COGNITO_REGION_DEV,
  COGNITO_IDENTITY_ID: env.REACT_APP_COGNITO_IDENTITY_ID_DEV,

  SECRET_ACCESS_KEY: env.REACT_APP_SECRET_ACCESS_KEY_DEV,
  ACCESS_KEY_ID: env.REACT_APP_ACCESS_KEY_ID_DEV,
  REGION: env.REACT_APP_REGION_DEV,
  BUCKET_NAME: env.REACT_APP_BUCKET_NAME_DEV,

  WEB3AUTH_VERIFIER: env.REACT_APP_WEB3AUTH_VERIFIER_DEV,

  WEB3AUTH_SUB_VERIFIER_SELF: env.REACT_APP_WEB3AUTH_SUB_VERIFIER_SELF_DEV,
  WEB3AUTH_CLIENT_ID: env.REACT_APP_WEB3AUTH_CLIENT_ID_DEV,

  CONTRACT_ADDRESS: env.REACT_APP_CONTRACT_ADDRESS_DEV,

  INTERCOM_APP_ID: env.REACT_APP_INTERCOM_APP_ID_DEV,

  GOOGLE_CLIENT_ID: env.REACT_APP_GOOGLE_CLIENT_ID_DEV,
  GOOGLE_CLIENT_SECRET: env.REACT_APP_GOOGLE_CLIENT_SECRET_DEV,

  GITHUB_CLIENT_ID: env.REACT_APP_GITHUB_CLIENT_ID_DEV
};

if (process.env.REACT_APP_ENV === 'production') {
  ENV.BASE_URL = env.REACT_APP_BASE_URL_PROD;
  ENV.COGNITO_REGION = env.REACT_APP_COGNITO_REGION_PROD;
  ENV.COGNITO_IDENTITY_ID = env.REACT_APP_COGNITO_IDENTITY_ID_PROD;

  ENV.SECRET_ACCESS_KEY = env.REACT_APP_SECRET_ACCESS_KEY_PROD;
  ENV.ACCESS_KEY_ID = env.REACT_APP_ACCESS_KEY_ID_PROD;
  ENV.REGION = env.REACT_APP_REGION_PROD;
  ENV.BUCKET_NAME = env.REACT_APP_BUCKET_NAME_PROD;

  ENV.WEB3AUTH_VERIFIER = env.REACT_APP_WEB3AUTH_VERIFIER_PROD;
  ENV.WEB3AUTH_SUB_VERIFIER_SELF =
    env.REACT_APP_WEB3AUTH_SUB_VERIFIER_SELF_PROD;
  ENV.WEB3AUTH_CLIENT_ID = env.REACT_APP_WEB3AUTH_CLIENT_ID_PROD;

  ENV.INTERCOM_APP_ID = env.REACT_APP_INTERCOM_APP_ID_PROD;

  ENV.GOOGLE_CLIENT_ID = env.REACT_APP_GOOGLE_CLIENT_ID_PROD;
  ENV.GOOGLE_CLIENT_SECRET = env.REACT_APP_GOOGLE_CLIENT_SECRET_PROD;

  ENV.GITHUB_CLIENT_ID = env.REACT_APP_GITHUB_CLIENT_ID_PROD;
}

export default ENV;
