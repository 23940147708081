import { BadgeRecipientFilterFormDefaultValues } from 'src/pages/dashboard/badge/issuer/components/badgeDetail/FilterRecipient';
import { api } from '../api';
import { EarningCriterias, SignedBadge } from 'src/models/badge';
import { delay } from 'src/utils/helperUtils';
import axios from 'axios';

// @gets walletAddress from token
// @returns issuer created badges
export const getBadgesAsIssuer = async (
  pageNumber: number,
  pageSize: number
) => {
  const response = await api.get(
    `badges/issuer?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getBadgesAsIssuer');
};
// @returns issuer created badges
export const getBadgesAsIssuerPublic = async (
  walletAddress: string,
  pageNumber: number,
  pageSize: number
) => {
  const response = await api.get(
    `public/badges/issuer/${walletAddress}?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getBadgesAsIssuerPublic');
};
// @gets walletAddress from token
// @returns badge details for issuer single badge
export const getIssuerBadgeDetail = async (badgeId: string) => {
  const response = await api.get(`/badges/${badgeId}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getIssuerBadgeDetail');
};

// @returns badge details for issuer single badge
export const getIssuerBadgeDetailPublic = async (badgeId: string) => {
  const response = await api.get(`public/badges/${badgeId}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getIssuerBadgeDetailPublic');
};

// @returns issuer issued bagdes that user is not registered
export const getAwatingUserRegistirationBadges = async (
  pageNumber: number,
  pageSize: number
) => {
  const response = await api.get(
    `issues/issuer/?pageNumber=${pageNumber}&pageSize=${pageSize}&statuses[]=AWAIT_ISSUER`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getAwatingUserRegistirationBadges');
};

// @returns issuer issued bagdes that user is not registered
export const getAwaitingSignaturesBadges = async (badgeId?: string) => {
  const response = await api.get(
    `issues/issuer/?toSign=true${badgeId ? `&badgeId=${badgeId}` : ''}`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getAwaitingSignaturesBadges');
};

//  @returns recipient list that badge is issued
export const getIssuedUsers = async (
  badgeId: string,
  pageNumber: number,
  pageSize: number,
  filterValues: BadgeRecipientFilterFormDefaultValues
) => {
  // console.log('getIssuedUsers filterValues:', filterValues);
  const { searchEmail, issueDate, status } = filterValues;
  const response = await api.get(
    `/issues/badge/${badgeId}?pageNumber=${pageNumber}&pageSize=${pageSize}${
      status.length > 0 ? `&statuses[]=${status}` : ''
    }${searchEmail ? `&searchEmail=${searchEmail}` : ''}${
      issueDate ? `&issueDate=${issueDate}` : ''
    }`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getIssuedUsers');
};

// @gets walletAddress from token
// @returns recipient issued badges
export const getBadgesAsUser = async (
  pageNumber: number,
  pageSize: number,
  status?: string
) => {
  const response = await api.get(
    `issues/account?pageNumber=${pageNumber}&pageSize=${pageSize}${
      status ? `&statuses[]=${status}` : ''
    }`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getBadgesAsUser');
};
// @returns recipient issued badges public
export const getBadgesAsUserPublic = async (
  walletAddress: string,
  pageNumber: number,
  pageSize: number,
  status?: string
) => {
  const response = await api.get(
    `public/issues/account/${walletAddress}?pageNumber=${pageNumber}&pageSize=${pageSize}${
      status ? `&statuses[]=${status}` : ''
    }`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getBadgesAsUserPublic');
};
// @gets walletAddress from token
// @returns badge details for user single badge
export const getUserBadgeDetail = async (issueId: string) => {
  const response = await api.get(`issues/${issueId}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getUserBadgeDetail');
};

// @returns badge details for user single badge public
export const getUserBadgeDetailPublic = async (issueId: string) => {
  const response = await api.get(`public/issues/${issueId}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getUserBadgeDetailPublic');
};

// @create new badge
export const createBadge = async (
  title: string,
  skills: string[],
  imageUrl: string,
  description: string,
  detailsUrl: string,
  earningCriterias: EarningCriterias[],
  earnThisCredentialUrl?: string
): Promise<{ id: number }> => {
  const data = {
    title,
    skills,
    imageUrl,
    description,
    detailsUrl,
    earningCriterias,
    earnThisCredentialUrl
  };

  const response = await api.post('badges', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('createBadge');
};
// @sign created new badge
export const signCreatedBadge = async (id: string, issuerSignature: string) => {
  const data = {
    issuerSignature
  };

  const response = await api.post(`badges/${id}/sign`, data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('signCreatedBadge');
};
// @edit a badge
export const editBadge = async (formData) => {
  const response = await api.put('badges', formData);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('editBadge');
};
// @issue new badge to users

// @issue new badge to users
export const createIssue = async (
  badgeId: string,
  recipientEmails: string[],
  expiresAt?: Date
) => {
  const data = {
    badgeId,
    recipientEmails,
    expiresAt
  };

  const response = await api.post('issues/bulk', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('createIssue');
};

// @issuer sign badges to make them ready for chain
export const issuerSignBadges = async (issues: SignedBadge[]) => {
  const data = {
    issues
  };

  const response = await api.post('issues/issuer_sign/bulk', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('issuerSignBadge');
};

// @recipient sign badge to make it ready for chain
export const recipientSignBadge = async (issue: SignedBadge) => {
  const data = issue;

  const response = await api.post('issues/recipient_sign', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('recipientSignBadge');
};

// @cancel issue badge to user (or user candidate)
export const cancelIssuedBadge = async (issueId: string) => {
  const data = {
    issueId
  };

  const response = await api.post('issues/cancel', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('cancelIssuedBadge');
};

export const updateBadgeVisibility = async (
  badgeId: string,
  isPublic: boolean
) => {
  const data = {
    isPublic
  };

  const response = await api.put(`badges/${badgeId}/visibility`, data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('updateIssueVisibility');
};

export const updateImportedCredentialVisibility = async (
  importedCredentialId: string,
  isPublic: boolean
) => {
  const data = {
    isPublic
  };

  const response = await api.put(
    `/imported_credentials/${importedCredentialId}/toggle_visibility`,
    data
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('updateImportedCredentialVisibility');
};

export const declineBadge = async (issueId: string) => {
  const data = {
    issueId
  };

  const response = await api.post('issues/decline', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('declineBadge');
};

export const updateIssueVisibility = async (
  issueId: string,
  isPublic: boolean
) => {
  const data = {
    issueId,
    isPublic
  };

  const response = await api.put('issues/visibility', data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('updateIssueVisibility');
};
export const getImportedBadgesAsUser = async (
  recipientAddress: string,
  pageNumber: number,
  pageSize: number
) => {
  const response = await api.get(
    `/imported_credentials?recipientAddress=${recipientAddress}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getBadgesAsUser');
};
export const getImportedBadgeDetailAsUser = async (importedBadgeId: string) => {
  const response = await api.get(`/imported_credentials/${importedBadgeId}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getImportedBadgeDetailAsUser');
};
export const initImportBadgeSession = async () => {
  const response = await api.post('imported_credentials/init_session');
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('initImportBadgeSession');
};

export const sendOtpForImportBadge = async (
  recipientEmail: string,
  importedBadgeId: number
) => {
  const data = { recipientEmail };

  const response = await api.post(
    `imported_credentials/${importedBadgeId}/send_otp`,
    data
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getEmailCodeForImportBadge');
};
export const verifyOtpForImportBadge = async (
  code: string,
  importedBadgeId: number
) => {
  const data = { code };

  const response = await api.post(
    `imported_credentials/${importedBadgeId}/verify_otp`,
    data
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getEmailCodeForImportBadge');
};
export const getS3UploadUrlForImportBadge = async (importedBadgeId: number) => {
  const data = {
    mimetype: 'image/png'
  };
  const response = await api.post(
    `imported_credentials/${importedBadgeId}/s3_upload`,
    data
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getS3UploadUrlForImportBadge');
};

export const s3UploadForImportBadge = async (
  presignedUrl: string,
  image: File
) => {
  const response = await axios.put(presignedUrl, image, {
    headers: {
      'Content-Type': 'image/png'
    }
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('s3UploadForImportBadge');
};

export const extractInfoFromImage = async (importedBadgeId: number) => {
  const response = await api.post(
    `imported_credentials/${importedBadgeId}/extract_info`
  );

  if (response.status === 200) {
    return response.data;
  }

  throw new Error('getEnailCodeForImportBadge');
};

export const completeImpotedBadge = async (
  importedBadgeId: number,
  recipientSignature: string
) => {
  const data = { recipientSignature };

  const response = await api.post(
    `imported_credentials/${importedBadgeId}/complete`,
    data
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('completeImpotedBadge');
};
