import { Stack, styled } from '@mui/material';

// export const CenteredStack = () => (
//   <Stack display="flex" justifyContent="center" alignItems="center" />
// );

export const CenteredStack = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export const CenteredStackRow = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row'
}));
