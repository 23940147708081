import { api } from '../api';

export const customLogin = async (formData) => {
  const response = await api.post(`login`, formData);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Login User');
};
export const loginWithGoogle = async (googleToken: string) => {
  const config = {
    headers: {
      Authorization: googleToken
    }
  };

  const response = await api.post(`login/google`, null, config);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Google Login');
};

export const customRegister = async (formData) => {
  const response = await api.post(`register`, formData);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Register User');
};

export const resendVerificationCode = async (email) => {
  const response = await api.post(`code/resend`, email);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Confirm Code');
};

export const confirmCustomRegisterCode = async (formData) => {
  const response = await api.post(`code/verify`, formData);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Confirm Code');
};

export const updatePublicAddress = async (publicAddress: string) => {
  const response = await api.put(`accounts/public-address`, {
    publicAddress
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Confirm Code');
};
export const changePassword = async (formData) => {
  const response = await api.put(`accounts/password`, formData);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Change Password ');
};
export const forgotPassword = async (email) => {
  const response = await api.post(`code/password`, { email });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Change Password ');
};

export const resetPassword = async (formData) => {
  const response = await api.post(`accounts/password/forgot`, formData);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Change Password ');
};

export const refreshIdToken = async () => {
  const response = await api.post(`idtoken`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Refresh token');
};

export const generateOtp = async () => {
  const response = await api.get(`otp/generate`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Generate Otp');
};

export const enableOtp = async (code: string) => {
  const response = await api.post(`otp/enable/?code=${code}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Enable Otp');
};

export const disableOtp = async () => {
  const response = await api.post(`otp/disable`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Disable Otp');
};

export const verifyOtp = async (code: string) => {
  const response = await api.post(`otp/verify?code=${code}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Verify Otp');
};

export const connectSocialAccount = async (
  socialAccount: string,
  code: string
) => {
  const response = await api.post(`accounts/${socialAccount}?code=${code}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Connect Social Account');
};
export const disconnectSocialAccount = async (socialAccount: string) => {
  const response = await api.delete(`accounts/${socialAccount}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Disconnect Social Account');
};

// @gets walletAddress from token
// @returns issuer details
export const getUser = async () => {
  const response = await api.get(`accounts`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};
export const getUserPublicAccount = async (walletAddress: string) => {
  const response = await api.get(`public/accounts/${walletAddress}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};
// @gets walletAddress from token
// @returns issuer details
export const updateUser = async (formData) => {
  const response = await api.put('accounts', formData);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Update User');
};

// @gets walletAddress from token
// @returns issuer details
export const getPublicAddresses = async (emails: string[]) => {
  const emailQuery = emails.map((email) => `emails[]=${email}`).join('&');
  const response = await api.get(`accounts/public-address?${emailQuery}`);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};

// @returns OK after saving user to db
export const saveUser = async () => {
  const response = await api.post('accounts');

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};

// @returns api key
export const generateApiKey = async () => {
  const response = await api.post('accounts/api-key');

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get Api Key');
};

// @returns api key
export const revokeApiKey = async () => {
  const response = await api.delete('accounts/api-key');

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Revoke Api Key');
};

// @gets walletAddress from token
// @returns issuer details
export const upgradeToIssuer = async (username: string) => {
  const data = {
    username
  };
  const response = await api.post('issuers', data);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};

// @returns subscriptions packages
export const getSubsPackages = async () => {
  const response = await api.get('packages');

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};

// @returns subscriptions url
export const getCheckoutSession = async (
  packageId: string,
  callbackUrl: string
) => {
  const data = {
    packageId,
    callbackUrl
  };
  const response = await api.post('subscriptions/checkout', data);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};
// @returns subscriptions portal url
export const getPortalSession = async (callbackUrl: string) => {
  const data = {
    callbackUrl
  };
  const response = await api.post('subscriptions/portal', data);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Get User');
};

// @returns liveness session id
export const createLiveNessSession = async () => {
  const response = await api.post('user/liveness_session');

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('createLiveNessSession');
};

// @returns liveness result
export const getLiveNessSessionResult = async () => {
  const response = await api.get('user/liveness_session');

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('getLiveNessSessionResult');
};

// @returns
export const verifyDocumentImage = async (
  idDocumentImage: File,
  firstName: string,
  lastName: string
) => {
  const form = new FormData();
  form.append('idDocument', idDocumentImage, idDocumentImage.name);
  form.append('firstName', firstName);
  form.append('lastName', lastName);

  const response = await api.post('user/liveness_session/verify', form);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error('verifyDocumentImage');
};
