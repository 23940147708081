import { useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import HeaderOnlyNavbar from './HeaderOnlyNavbar';
import HeaderOnlyFooter from './HeaderOnlyFooter';
import Header from '../BaseLayout/Header';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  background: theme.palette.common.white
}));

const MainStyle = styled('div')(({ theme }) => ({
  // height: '100%',
  flex: 1,
  zIndex: 5,
  paddingTop: `calc(${theme.header.height})`,

  // apply padding for desktop version
  [theme.breakpoints.up('sm')]: {
    paddingInline: '6rem',
    paddingTop: `calc(${theme.header.height} + 2rem)`
  }
}));

// ----------------------------------------------------------------------

export default function HeaderOnlyLayout() {
  return (
    <RootStyle>
      <Header />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <HeaderOnlyFooter />
    </RootStyle>
  );
}
